<template>
  <base-section
    id="evaluation"
  >
    <base-section-heading
      icon="mdi-lightbulb-on-outline"
      title="Evaluation & Assessment"
    />
      <v-row
        class="mx-auto my-auto"
      >
        <v-col
          md="3"
        >
          <v-img
            :height="$vuetify.breakpoint.mdAndUp ? 300 : 225"
            contain
            :src="require('@/assets/evaluation.jpeg')"
          />
        </v-col>
        <v-col
          class="my-auto py-auto"
          md="8"
        >
          <v-row
            class="my-auto py-auto"
          >
            <v-col
              v-for="card in cards"
              :key="card.title"
              cols="12"
              md="3"
            >
              <base-info-card-pacific v-bind="card" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
        >
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionEvaluation',

    data: () => ({
      cards: [
        {
          title: 'Written Tests / Quizzes',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Practical Assignments / Projects',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Performance-based Assessments',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Peer Reviews and Presentations',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Simulations and Virtual Environments',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Continuous Evaluation',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Feedback Surveys',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
        {
          title: 'Certification / Examinations',
          icon: 'mdi-checkbox-marked',
          callout: '',
        },
      ],
    }),
  }
</script>
